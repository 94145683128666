.media-text-columns {
    padding-bottom: 20px;

    .btn {
        margin-bottom: 0px;
    }

    .static-header {
        &.increase-margin {
            margin-bottom: 45px;
        }
    }

    .inner-content {
        margin-bottom: 45px;
    
        p,
        ul {
            margin-bottom: 15px;
        }
    
        a {
            font-size: 14px;
            text-decoration: none;
        }
    }
    

    .banner-wrapper {
        margin-bottom: 50px;

        img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .prime-column {
        background: #2c3e50;
        padding: 10px 10px;
        padding-bottom: 50px;
        margin-bottom: 20px;
    }

    .dynamic-header {
        margin-bottom: 0px;
    }

    .column-top {
        margin-top: 40px;
    }

    .bold-title {
        font-family: 'SST W20 Bold', verdana, sans-serif;
    }

    .columns {
        .text-column {
            margin-bottom: auto;
            height: auto;

            .text-column-image {
                img {
                    margin: auto auto 25px;
                }
            }
            .text-column-image-ps {
                img {
                    margin: auto auto 0px;
                }
            }
            .button-position {
                bottom: 0;
                position: absolute;
                margin: 0 auto;
                width: 92%;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .media-text-columns {
        .column-inner-content {
            min-height: 300px;
        }
        .columns {
            .btn {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .media-text-columns {
        text-align: center;

        .inner-content {
            margin-bottom: 5px;
            padding: 0 5px 5px;
        }

        .column-inner-content {
            min-height: 0px;
        }

        .prime-column {
            text-align: left;
        }

        .columns {
            .text-column {
                display: grid;
                height: auto;
                padding-bottom: 50px;

                .text-column-image {
                    order: 1;

                    img {
                        margin-bottom: 0px;
                    }
                }
                .btn {
                    width: 100%;
                }
            }
        }
    }

    .media-text-columns + .media-text-columns-no-title,
    .media-text-columns + .text-media-variable-no-title,
    .media-text-columns + .standard-text-no-title,
    .media-text-columns + .standard-table-no-title {
        margin-top: 0;
    }
}

.media-text-columns + .media-text-columns-no-title,
.media-text-columns + .text-media-variable-no-title,
.media-text-columns + .standard-text-no-title,
.media-text-columns + .standard-table-no-title {
  padding-top: 0;
}